import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import styles from './value-score.module.scss'

export const ValueScore = ({ rating, size = 'md' }) => {
  const [range, setRange] = useState('')
  const [height, setHeight] = useState(35)
  const [width, setWidth] = useState(40)
  const [strokeWidth, setStrokeWidth] = useState(4)
  const [stroke, setStroke] = useState('#DF342F')

  useEffect(() => {
    if (size === 'md') {
      setHeight(35)
      setWidth(40)
      setStrokeWidth(4)
    }

    if (size === 'lg') {
      setHeight(49)
      setWidth(56)
      setStrokeWidth(5.6)
    }

    if (size === 'xl') {
      setHeight(96)
      setWidth(96)
      setStrokeWidth(9.6)
    }
  }, [size])

  useEffect(() => {
    if (rating >= 0 && rating <= 69) {
      setRange('low')
      setStroke('#f4be54')
    } else if (rating >= 70 && rating <= 79) {
      setRange('mid')
      setStroke('#e1833e')
    } else if (rating >= 80 && rating <= 89) {
      setRange('hot')
      setStroke('#eb4868')
    } else if (rating >= 90) {
      setRange('loco')
      setStroke('#EA2027')
    }
  }, [rating])

  return (
    <div
      className={`${styles['value-score']} ${styles[`range-${range}`]} ${styles[`size-${size}`]}`}>
      <div className={styles.image}>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          {size === 'md' && (
            <>
              <style>
                {`
                  .path-md-${rating} {
                    stroke-dashoffset: ${
                      rating !== 0 ? (rating < 50 ? 400 - ((rating / 100) * 85).toFixed(0) : 0) : 90
                    };
                    stroke-dasharray: ${
                      rating !== 0 ? (rating < 50 ? 400 : ((rating / 100) * 85).toFixed(0)) : 90
                    };
                    animation: dashMd${rating} 2s linear forwards;
                  }

                  @keyframes dashMd${rating} {
                    0% {
                      stroke-dashoffset: 100;
                      stroke-dasharray: 100;
                    }


                    100% {
                      stroke-dashoffset: ${
                        rating !== 0
                          ? rating < 50
                            ? 400 - ((rating / 100) * 85).toFixed(0)
                            : 0
                          : 90
                      };
                      stroke-dasharray: ${
                        rating !== 0 ? (rating < 50 ? 400 : ((rating / 100) * 85).toFixed(0)) : 90
                      };
                    }
                  }
                `}
              </style>
              <path
                d="M32.8546 32.6C36.0374 29.3533 38 24.9058 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 24.9058 3.96257 29.3533 7.14542 32.6"
                stroke="#EBEAEB"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className={`path-md-${rating}`}
                d="M7.14542 32.6C3.96258 29.3533 2 24.9058 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20C38 24.9058 36.0374 29.3533 32.8546 32.6"
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </>
          )}
          {size === 'lg' && (
            <>
              <style>
                {`
                  .path-lg-${rating} {
                    stroke-dashoffset: ${
                      rating !== 0
                        ? rating < 50
                          ? 600 - ((rating / 100) * 120).toFixed(0)
                          : 0
                        : 120
                    };
                    stroke-dasharray: ${
                      rating !== 0 ? (rating < 50 ? 600 : ((rating / 100) * 120).toFixed(0)) : 120
                    };
                    animation: dashLg${rating} 2s linear forwards;
                  }

                  @keyframes dashLg${rating} {
                    0% {
                      stroke-dashoffset: 120;
                      stroke-dasharray: 120;
                    }


                    100% {
                      stroke-dashoffset: ${
                        rating !== 0
                          ? rating < 50
                            ? 600 - ((rating / 100) * 120).toFixed(0)
                            : 0
                          : 120
                      };
                      stroke-dasharray: ${
                        rating !== 0 ? (rating < 50 ? 600 : ((rating / 100) * 120).toFixed(0)) : 120
                      };
                    }
                  }
                `}
              </style>
              <path
                d="M45.9964 45.64C50.4524 41.0945 53.2 34.8681 53.2 28C53.2 14.0824 41.9176 2.79999 28 2.79999C14.0824 2.79999 2.79999 14.0824 2.79999 28C2.79999 34.8681 5.54759 41.0945 10.0036 45.64"
                stroke="#EBEAEB"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className={`path-lg-${rating}`}
                d="M10.0036 45.64C5.54762 41.0945 2.80001 34.8681 2.80001 28C2.80001 14.0824 14.0824 2.79999 28 2.79999C41.9176 2.79999 53.2 14.0824 53.2 28C53.2 34.8681 50.4524 41.0945 45.9964 45.64"
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </>
          )}
          {size === 'xl' && (
            <>
              <style>
                {`
                  .path-xl-${rating} {
                    stroke-dashoffset: ${
                      rating !== 0
                        ? rating < 50
                          ? 1050 - ((rating / 100) * 210).toFixed(0)
                          : 0
                        : 210
                    };
                    stroke-dasharray: ${
                      rating !== 0 ? (rating < 50 ? 1050 : ((rating / 100) * 210).toFixed(0)) : 210
                    };
                    animation: dashXl${rating} 2s linear forwards;
                  }

                  @keyframes dashXl${rating} {
                    0% {
                      stroke-dashoffset: 210;
                      stroke-dasharray: 210;
                    }


                    100% {
                      stroke-dashoffset: ${
                        rating !== 0
                          ? rating < 50
                            ? 1050 - ((rating / 100) * 210).toFixed(0)
                            : 0
                          : 210
                      };
                      stroke-dasharray: ${
                        rating !== 0
                          ? rating < 50
                            ? 1050
                            : ((rating / 100) * 210).toFixed(0)
                          : 210
                      };
                    }
                  }
                `}
              </style>
              <path
                d="M78.8508 78.5831C86.4896 70.7909 91.1998 60.117 91.1998 48.3431C91.1998 24.4844 71.8585 5.14307 47.9998 5.14307C24.1411 5.14307 4.7998 24.4844 4.7998 48.3431C4.7998 60.117 9.50998 70.7909 17.1488 78.5831"
                stroke="#EBEAEB"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className={`path-xl-${rating}`}
                d="M17.1492 78.5826C9.51037 70.7904 4.80019 60.1165 4.80019 48.3426C4.80019 24.4839 24.1415 5.14258 48.0002 5.14258C71.8589 5.14258 91.2002 24.4839 91.2002 48.3426C91.2002 60.1165 86.49 70.7904 78.8512 78.5826"
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </>
          )}
        </svg>
      </div>
      <p className={styles.rating}>{rating}</p>
      <p className={styles.range}>{`${range.toUpperCase()}${size === 'xl' ? ' DEAL' : ''}`}</p>
    </div>
  )
}

ValueScore.propTypes = {
  rating: PropTypes.number,
  size: PropTypes.string,
}
